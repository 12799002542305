<template>
  <TableViewWithTitle title="거래처">
    <template v-slot:colgroup>
      <col style="width: 310px" />
      <col style="width: 310px" />
      <col style="width: 310px" />
      <col style="width: 310px" />
      <col style="width: 310px" />
      <col style="width: 310px" />
      <col style="width: 310px" />
      <col style="width: 310px" />
    </template>
    <template v-slot:header>
      <tr>
        <th colspan="4">공급자</th>
        <th colspan="4">공급받는자</th>
      </tr>
    </template>
    <template v-slot:body>
      <tr>
        <th>등록번호</th>
        <td colspan="3">
          {{ supplier.corporateNum }}
        </td>
        <th>등록번호</th>
        <td colspan="3">
          {{ recipient.corporateNum }}
        </td>
      </tr>
      <tr>
        <th>상호(법인명)</th>
        <td colspan="3">
          {{ supplier.name }}
        </td>
        <th>상호(법인명)</th>
        <td colspan="3">
          {{ recipient.name }}
        </td>
      </tr>
      <tr>
        <th>사업장주소</th>
        <td colspan="3">
          {{ supplier.address }}
        </td>
        <th>사업장주소</th>
        <td colspan="3">
          {{ recipient.address }}
        </td>
      </tr>
      <tr>
        <th>업태</th>
        <td>
          {{ supplier.conditions | nullToDash }}
        </td>
        <th>종목</th>
        <td>
          {{ supplier.type | nullToDash }}
        </td>
        <th>업태</th>
        <td>
          {{ recipient.conditions | nullToDash }}
        </td>
        <th>종목</th>
        <td>
          {{ recipient.type | nullToDash }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>
<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
export default {
  name: "TransactionClientInfoTbl",
  components: { TableViewWithTitle },
  props: {
    supplier: {
      type: Object,
      default: () => {
        return {
          corporateNum: "",
          name: "",
          address: "",
          conditions: "",
          type: "",
        };
      },
    },
    recipient: {
      type: Object,
      default: () => {
        return {
          corporateNum: "",
          name: "",
          address: "",
          conditions: "",
          type: "",
        };
      },
    },
  },
};
</script>
