<template>
  <Popup popupType="tableType" :width="'824px'">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">미리보기</h3>
      </div>
      <div id="wrapPrint" class="body_section">
        <!-- 세금계산서 -->
        <div
          v-if="invoiceData.invoicerCorpNum"
          class="wrap_transaction"
          :class="computedTypeClassName"
        >
          <div class="head_transaction">
            <span> [별지 제11호 서식] </span>
            <span class="right"> {{ this.type === "supplier" ? "[적색]" : "[청색]" }} </span>
          </div>
          <table class="tbl_transaction bb_0">
            <colgroup>
              <col style="width: 570px" />
              <col style="width: 50px" />
              <col style="width: 60px" />
              <col style="width: 120px" />
            </colgroup>
            <tr class="row_head">
              <td rowspan="2" class="td_subject">
                <strong class="tit_transaction">세금계산서</strong>
                <span class="txt_transaction"
                  >({{ type === "supplier" ? "공급자 보관용" : "공급받는자 보관용" }})</span
                >
              </td>
              <td class="align_center td_tit">책 번호</td>
              <td class="bl_1 align_right">{{ "" }}<span class="txt_suffix">권</span></td>
              <td class="bl_1 align_right">{{ "" }}<span class="txt_suffix">호</span></td>
            </tr>
            <tr class="row_head">
              <td class="align_center td_tit">일련번호</td>
              <td colspan="2" class="bl_1 bt_1">
                {{ invoiceData.ntsconfirmNum | taxInvoceNumber }}
              </td>
            </tr>
          </table>
          <!-- 거래처정보 -->
          <table class="tbl_transaction type_cont">
            <colgroup>
              <col style="width: 20px" />
              <col style="width: 60px" />
              <col style="width: 130px" />
              <col style="width: 60px" />
              <col style="width: 130px" />
              <col style="width: 20px" />
              <col style="width: 60px" />
              <col style="width: 130px" />
              <col style="width: 60px" />
              <col style="width: 130px" />
            </colgroup>
            <tr>
              <td class="td_provider" rowspan="4">공<br />급<br />자</td>
              <td class="td_tit">등록번호</td>
              <td class="td_str" colspan="3">
                {{ invoiceData.invoicerCorpNum | corporateNum }}
              </td>
              <td class="td_provider" rowspan="4">공<br />급<br />받<br />는<br />자</td>
              <td class="td_tit">등록번호</td>
              <td class="td_str" colspan="3">
                {{ invoiceData.invoiceeCorpNum | corporateNum }}
              </td>
            </tr>
            <tr>
              <td class="td_tit">상호<br />(법인명)</td>
              <td>{{ invoiceData.invoicerCorpName }}</td>
              <td class="td_tit">성명<br />(대표자)</td>
              <td>{{ invoiceData.invoicerCEOName }}</td>
              <td class="td_tit">상호<br />(법인명)</td>
              <td>{{ invoiceData.invoiceeCorpName }}</td>
              <td class="td_tit">성명<br />(대표자)</td>
              <td>{{ invoiceData.invoiceeCEOName }}</td>
            </tr>
            <tr>
              <td class="td_tit">사업장<br />주소</td>
              <td colspan="3">{{ invoiceData.invoicerAddr }}</td>
              <td class="td_tit">사업장<br />주소</td>
              <td colspan="3">{{ invoiceData.invoiceeAddr }}</td>
            </tr>
            <tr>
              <td class="td_tit">업태</td>
              <td>{{ invoiceData.invoicerBizType }}</td>
              <td class="td_tit">종목</td>
              <td>{{ invoiceData.invoicerBizClass }}</td>
              <td class="td_tit">업태</td>
              <td>{{ invoiceData.invoiceeBizType }}</td>
              <td class="td_tit">종목</td>
              <td>{{ invoiceData.invoiceeBizClass }}</td>
            </tr>
          </table>
          <!-- 거래처정보 -->
          <table class="tbl_transaction type_cont">
            <colgroup>
              <col style="width: 40px" />
              <col style="width: 34px" />
              <col style="width: 34px" />
              <col style="width: 50px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 23px" />
              <col style="width: 159px" />
            </colgroup>
            <tr>
              <td class="td_tit bt_0" colspan="3">작성</td>
              <td class="td_tit bt_0" colspan="12">공급가액</td>
              <td class="td_tit bt_0" colspan="10">세액</td>
              <td class="td_tit bt_0">비고</td>
            </tr>
            <tr>
              <td class="td_tit">년</td>
              <td class="td_tit">월</td>
              <td class="td_tit">일</td>
              <td class="td_tit">공란수</td>
              <td class="td_tit">백</td>
              <td class="td_tit">십</td>
              <td class="td_tit">억</td>
              <td class="td_tit">천</td>
              <td class="td_tit">백</td>
              <td class="td_tit">십</td>
              <td class="td_tit">만</td>
              <td class="td_tit">천</td>
              <td class="td_tit">백</td>
              <td class="td_tit">십</td>
              <td class="td_tit">일</td>
              <td class="td_tit">십</td>
              <td class="td_tit">억</td>
              <td class="td_tit">천</td>
              <td class="td_tit">백</td>
              <td class="td_tit">십</td>
              <td class="td_tit">만</td>
              <td class="td_tit">천</td>
              <td class="td_tit">백</td>
              <td class="td_tit">십</td>
              <td class="td_tit">일</td>
              <!-- 비고 -->
              <td class="align_left" rowspan="2">
                {{ invoiceData.remark1 || invoiceData.remark2 || invoiceData.remark3 }}
              </td>
            </tr>
            <tr class="tr_cost">
              <!-- 작성일자 -->
              <td class="align_center">
                {{ moment(invoiceData.writeDate, "YYYYMMDD").format("YYYY") }}
              </td>
              <td class="align_center">
                {{ moment(invoiceData.writeDate, "YYYYMMDD").format("MM") }}
              </td>
              <td class="align_center">
                {{ moment(invoiceData.writeDate, "YYYYMMDD").format("DD") }}
              </td>
              <!-- 공란수 -->
              <td class="align_center">
                {{ 11 - String(invoiceData.supplyCostTotal).length }}
              </td>
              <!-- 공급가액 -->
              <td
                v-for="(num, index) in supplyCostTotal(invoiceData.supplyCostTotal)"
                :key="`supplyPrice_${index}`"
              >
                {{ num || "" }}
              </td>

              <!-- 세액 -->
              <td v-for="(num, index) in taxTotal(invoiceData.taxTotal)" :key="`total_${index}`">
                {{ num || "" }}
              </td>
            </tr>
          </table>
          <!-- 품목정보 -->
          <table class="tbl_transaction type_cont">
            <colgroup>
              <col style="width: 30px" />
              <col style="width: 30px" />
              <col style="width: 180px" />
              <col style="width: 80px" />
              <col style="width: 60px" />
              <col style="width: 120px" />
              <col style="width: 120px" />
              <col style="width: 120px" />
              <col style="width: 60px" />
            </colgroup>
            <tr>
              <td class="td_tit bt_0">월</td>
              <td class="td_tit bt_0">일</td>
              <td class="td_tit bt_0">품목</td>
              <td class="td_tit bt_0">규격</td>
              <td class="td_tit bt_0">수량</td>
              <td class="td_tit bt_0">단가</td>
              <td class="td_tit bt_0">공급가액</td>
              <td class="td_tit bt_0">세액</td>
              <td class="td_tit bt_0">비고</td>
            </tr>
            <!-- 품목 -->

            <tr v-for="(item, idx) in invoiceData.detailList" class="tr_product" :key="idx">
              <td class="align_center">
                {{ moment(item.purchaseDT, "YYYYMMDD").format("MM") }}
              </td>
              <td class="align_center">
                {{ moment(item.purchaseDT, "YYYYMMDD").format("DD") }}
              </td>
              <td>
                <!-- 품목-->
                {{ item.itemName }}
              </td>
              <td>
                <!-- 규격-->
                {{ item.spec }}
              </td>
              <td class="align_right">
                <!-- 수량-->
                {{ item.qty | currency }}
              </td>
              <td class="align_right">
                <!-- 단가-->
                {{ item.unitCost | currency }}
              </td>
              <td class="align_right">
                <!-- 공급가액-->
                {{ item.supplyCost | currency }}
              </td>
              <td class="align_right">
                <!-- 세액-->
                {{ item.tax | currency }}
              </td>
              <td>
                <!-- 비고-->
                {{ item.remark }}
              </td>
            </tr>
          </table>
          <table class="tbl_transaction type_cont">
            <colgroup>
              <col style="width: 124px" />
              <col style="width: 124px" />
              <col style="width: 124px" />
              <col style="width: 124px" />
              <col style="width: 124px" />
              <col style="width: 180px" />
            </colgroup>
            <tr>
              <td class="td_tit bt_0">합계금액</td>
              <td class="td_tit bt_0">현금</td>
              <td class="td_tit bt_0">수표</td>
              <td class="td_tit bt_0">어음</td>
              <td class="td_tit bt_0">외상미수금</td>
              <td class="td_claim bt_0" rowspan="2">
                <div>이 금액을</div>
                <div>
                  <template v-if="isClaim"> 영수<br /><strong>[청구]</strong> </template>
                  <template v-else> <strong>영수</strong><br />[청구] </template>
                </div>
                <div>함.</div>
              </td>
            </tr>
            <tr class="tr_cost">
              <!-- 합계금액 -->
              <td>{{ invoiceData.totalAmount | currency }}</td>
              <!-- 현금 -->
              <td>{{ invoiceData.cash || 0 | currency }}</td>
              <!-- 수표 -->
              <td>{{ invoiceData.chkBill || 0 | currency }}</td>
              <!-- 어음 -->
              <td>{{ invoiceData.note || 0 | currency }}</td>
              <!-- 미수금 -->
              <td>{{ invoiceData.credit || 0 | currency }}</td>
            </tr>
          </table>
        </div>
        <!-- //세금계산서 -->

        <p class="txt_info">
          위 세금계산서는 전자적으로 발행된 세금계산서를 출력한 것이며, 전자 세금계산서는
          부가가치세법 제 35조 2항 및 동법 제 53조 제 6항에 의해 인정되었으며 대통령령 17041호에
          의해 시행 되었습니다.
        </p>
      </div>
    </div>
    <div class="group_btn">
      <button class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button class="btn_tertiary btn_medium" @click="print">인쇄</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import { Printd } from "printd";

export default {
  name: "TaxInvoicePopup",
  components: {
    Popup,
  },
  mixins: [PageMixin],
  props: {
    type: String,
    taxInvoiceNo: String,
  },
  data() {
    return {
      isClaim: true,
      invoiceData: {},
    };
  },
  computed: {
    computedTypeClassName() {
      //red = type_supplier, blue = type_recipient
      let ret = "";
      if (this.type === "supplier") {
        ret = "type_supplier";
      } else {
        ret = "type_recipient";
      }
      return ret;
    },
  },
  created() {
    this.getTaxInvoiceNo();
  },
  methods: {
    async getTaxInvoiceNo() {
      const path = this.$apiPath.TAX_INVOICE;
      const result = await ApiService.shared.getData(`${path}${this.taxInvoiceNo}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.invoiceData = _.cloneDeep(data);
    },
    supplyCostTotal(supplyCostTotal) {
      const maxPrice = _.padStart(String(supplyCostTotal), 11, " ");
      const ret = maxPrice.split("");
      return ret;
    },
    taxTotal(taxTotal) {
      const maxTax = _.padStart(String(taxTotal), 10, " ");
      const ret = maxTax.split("");
      return ret;
    },

    // 닫기 버튼
    onClickClose() {
      this.$emit("onClickClose");
    },
    print() {
      const printCss = `
      @charset "utf-8";
@media print {
  .head_transaction {
  position: relative;
  font-size: 12px;
}
.head_transaction .right {
  position: absolute;
  right: 0;
}
.tbl_transaction {
  width:50%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
.tbl_transaction td {
  font-size: 12px;
  padding: 1px 2px;
}
.tbl_transaction + .type_cont td {
  min-height: 30px;
}
.tbl_transaction + .type_cont .td_provider {
  text-align: center;
}
.tbl_transaction + .type_cont .td_tit {
  text-align: center;
}
.tbl_transaction + .type_cont .td_str {
  text-align: center;
  font-weight: bold;
  font-size: 13px;
}
.tbl_transaction + .type_cont .tr_cost {
  text-align: right;
  height: 20px;
}
.tbl_transaction + .type_cont .tr_product td {
  height: 20px;
}
.tbl_transaction .align_center {
  text-align: center !important;
}
.tbl_transaction .align_right {
  text-align: right !important;
}
.tbl_transaction .row_head .td_subject {
  padding: 5px;
  text-align: center;
}
.tbl_transaction .tit_transaction {
  font-size: 24px;
}
.tbl_transaction .txt_transaction {
  margin-left: 4px;
  font-size: 18px;
}
.tbl_transaction .td_ellip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.tbl_transaction .td_claim {
  text-align: center;
}
.tbl_transaction .td_claim div {
  vertical-align: middle;
  display: inline-block;
  padding: 2px;
}
.type_supplier .head_transaction {
  color: red;
}
.type_supplier .tbl_transaction {
  border: 1px solid red;
}
.type_supplier .tbl_transaction + .type_cont {
  border: 0;
}
.type_supplier .tbl_transaction + .type_cont td {
  border: 1px solid red;
}
.type_supplier .tbl_transaction + .type_cont .td_provider {
  color: red;
}
.type_supplier .tbl_transaction + .type_cont .td_tit {
  color: red;
}
.type_supplier .tbl_transaction .row_head .td_subject,
.type_supplier .tbl_transaction .row_head .td_tit {
  color: red;
}
.type_supplier .tbl_transaction .row_head .txt_suffix {
  color: red;
}
.type_supplier .tbl_transaction .td_claim {
  color: red;
}
.type_supplier .bl_1 {
  border-left: 1px solid red;
}
.type_supplier .bt_1 {
  border-top: 1px solid red;
}
.type_recipient .head_transaction {
  color: blue;
}
.type_recipient .tbl_transaction {
  border: 1px solid blue;
}
.type_recipient .tbl_transaction + .type_cont {
  border: 0;
}
.type_recipient .tbl_transaction + .type_cont td {
  border: 1px solid blue;
}
.type_recipient .tbl_transaction + .type_cont .td_provider {
  color: blue;
}
.type_recipient .tbl_transaction + .type_cont .td_tit {
  color: blue;
}
.type_recipient .tbl_transaction .row_head .td_subject,
.type_recipient .tbl_transaction .row_head .td_tit {
  color: blue;
}
.type_recipient .tbl_transaction .row_head .txt_suffix {
  color: blue;
}
.type_recipient .tbl_transaction .td_claim {
  color: blue;
}
.type_recipient .bl_1 {
  border-left: 1px solid blue;
}
.type_recipient .bt_1 {
  border-top: 1px solid blue;
}
.txt_info {
  margin-top: 14px;
  font-size: 12px;
  color: #999;
}
.bt_0 {
  border-top: 0 !important;
}
.bb_0 {
  border-bottom: 0 !important;
}
.purchase_layer .inner_comm_layer .layer_body > .group_btn {
  border-top: 0;
}}
  `;

      const wrapPrint = this.$el.querySelector("#wrapPrint");
      const d = new Printd();
      d.print(wrapPrint, [printCss]);
    },
  },
};
</script>
<style lang="scss" scoped>
$transaction-color: red;
$transaction-color2: blue;

.head_transaction {
  position: relative;
  font-size: 12px;
  .right {
    position: absolute;
    right: 0;
  }
}

.tbl_transaction {
  min-width: 100%;
  // width: 800px;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  td {
    font-size: 12px;
    padding: 1px 2px;
  }
  & + .type_cont {
    td {
      min-height: 30px;
    }

    .td_provider {
      text-align: center;
      // writing-mode: tb-rl;
      // letter-spacing: 4px;
    }

    .td_tit {
      text-align: center;
    }

    .td_str {
      text-align: center;
      font-weight: bold;
      font-size: 13px;
    }

    .tr_cost {
      text-align: right;
      height: 20px;
    }
    .tr_product td {
      height: 20px;
    }
  }

  .align_center {
    text-align: center !important;
  }
  .align_right {
    text-align: right !important;
  }

  .row_head {
    .td_subject {
      padding: 5px;
      text-align: center;
    }
  }
  .tit_transaction {
    font-size: 24px;
  }
  .txt_transaction {
    margin-left: 4px;
    font-size: 18px;
  }

  .td_ellip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .td_claim {
    text-align: center;
  }
  .td_claim div {
    vertical-align: middle;
    display: inline-block;
    padding: 2px;
  }
}

.type_supplier {
  .head_transaction {
    color: $transaction-color;
  }
  .tbl_transaction {
    border: 1px solid $transaction-color;
    & + .type_cont {
      border: 0;
      td {
        border: 1px solid $transaction-color;
      }
      .td_provider {
        color: $transaction-color;
      }
      .td_tit {
        color: $transaction-color;
      }
    }
    .row_head {
      .td_subject,
      .td_tit {
        color: $transaction-color;
      }
      .txt_suffix {
        color: $transaction-color;
      }
    }
    .td_claim {
      color: $transaction-color;
    }
  }
  .bl_1 {
    border-left: 1px solid $transaction-color;
  }
  .bt_1 {
    border-top: 1px solid $transaction-color;
  }
}

.type_recipient {
  .head_transaction {
    color: $transaction-color2;
  }
  .tbl_transaction {
    border: 1px solid $transaction-color2;
    & + .type_cont {
      border: 0;
      td {
        border: 1px solid $transaction-color2;
      }
      .td_provider {
        color: $transaction-color2;
      }
      .td_tit {
        color: $transaction-color2;
      }
    }
    .row_head {
      .td_subject,
      .td_tit {
        color: $transaction-color2;
      }
      .txt_suffix {
        color: $transaction-color2;
      }
    }
    .td_claim {
      color: $transaction-color2;
    }
  }
  .bl_1 {
    border-left: 1px solid $transaction-color2;
  }
  .bt_1 {
    border-top: 1px solid $transaction-color2;
  }
}

.txt_info {
  margin-top: 14px;
  font-size: 12px;
  color: #999;
}

.bt_0 {
  border-top: 0 !important;
}
.bb_0 {
  border-bottom: 0 !important;
}

.purchase_layer .inner_comm_layer .layer_body > .group_btn {
  border-top: 0;
}
</style>
