<template>
  <tr>
    <td class="align_center">
      <!-- 월-->
      {{ invoiceDateObj.month }}
    </td>
    <td class="align_center">
      <!-- 일-->
      {{ invoiceDateObj.day }}
    </td>
    <td>
      <!-- 품목-->
      {{ rowData.itemName }}
    </td>
    <td>
      <!-- 규격-->
      {{ rowData.unitValue }}
    </td>
    <td class="align_right">
      <!-- 수량-->
      {{ rowData.quantity | currency }}
    </td>
    <td class="align_right">
      <!-- 단가-->
      {{ rowData.price | currency }}
    </td>
    <td class="align_right">
      <!-- 공급가액-->
      {{ rowData.supplyPrice | currency }}
    </td>
    <td class="align_right">
      <!-- 세액-->
      {{ rowData.tax | currency }}
    </td>
    <td>
      <!-- 비고-->
      {{ rowData.note }}
    </td>
  </tr>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  name: "TransactionGoodsListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
    invoiceDateObj: Object,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
