<template>
  <tr>
    <!-- 일자 -->
    <td class="align_center">
      {{ rowData.regDate | dateTimeStringFormat }}
    </td>
    <!-- 담당자 -->
    <td class="align_center">
      {{ rowData.regId }}
    </td>
    <!-- 상태 -->
    <td class="align_center">
      {{ rowData.agreeYn ? "승인" : "수정요청" }}
    </td>
    <!-- 사유 -->
    <td v-html="computedDisagreeReason"></td>
  </tr>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";

import { escapeRegExp } from "@/utils/spUtils.js";
export default {
  name: "TransactionHistoryListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  data() {
    return {};
  },
  computed: {
    computedDisagreeReason() {
      return escapeRegExp(this.rowData.disagreeReason);
    },
  },
};
</script>
