<template>
  <TableViewWithTitle v-if="!isCreate" title="거래 정보">
    <template v-slot:body>
      <tr>
        <th>상태</th>
        <td colspan="3">
          <template v-if="itemData.issue !== 'SUCCESS'">
            <strong :class="computedTaxValidation">{{ taxValidation | nullToDash }}</strong>
          </template>
          <template v-else>
            <button class="link_subject" :class="computedTaxValidation" @click="onClickTaxInvoice">
              {{ taxValidation | nullToDash }}
            </button>
          </template>
        </td>
      </tr>
      <tr>
        <th>국세청승인번호</th>
        <td colspan="3">
          {{ taxInvoiceNoSync | nullToDash }}
        </td>
      </tr>
      <tr>
        <th>발행일자</th>
        <td colspan="3">
          {{ taxInvoiceDate | nullToDash }}
        </td>
      </tr>
      <!-- <tr>
        <th>승인번호</th>
        <td colspan="3">
          {{ taxInvoiceApprovalNum }}
        </td>
      </tr> -->
      <tr>
        <th>첨부파일</th>
        <td colspan="3">
          <FileView :dataList="attachFileListSync || []" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>

  <TableViewWithTitle v-else title="거래 정보">
    <template v-slot:body>
      <tr>
        <th>상태</th>
        <td colspan="3">
          <template v-if="itemData.issue !== 'SUCCESS'">
            <strong :class="computedTaxValidation">{{ taxValidation | nullToDash }}</strong>
          </template>
          <template v-else>
            <button class="link_subject" :class="computedTaxValidation" @click="onClickTaxInvoice">
              {{ taxValidation | nullToDash }}
            </button>
          </template>
        </td>
      </tr>
      <tr>
        <th>국세청승인번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input
            :isOnlyNumberAndEnglish="true"
            class="w405"
            placeholder="세금계산서24자리를 입력하세요"
            :value.sync="taxInvoiceNoSync"
            :maxLength="26"
          />
          <p class="desc_tip tc_red">
            <strong> 국세청승인번호를 복사해서 붙여 넣어주세요.</strong>
          </p>
          <!--@update:value="autoDash"-->
        </td>
      </tr>
      <tr>
        <th>발행일자<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <DatePicker :dateString.sync="taxInvoiceDateSync" />
          <p class="desc_tip tc_red"><strong> 정확한 발행일자를 넣어주세요.</strong></p>
        </td>
      </tr>
      <!-- <tr>
        <th>승인번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input
            class="w405"
            placeholder="승인번호를 입력하세요"
            :value.sync="taxInvoiceApprovalNumSync"
            :maxLength="24"
          />
        </td>
      </tr> -->
      <tr>
        <th>첨부파일</th>
        <td colspan="3">
          <FileWrite
            :canMultiple="false"
            :dataList="attachFileList"
            @fileUploaded="attachFileListUploaded"
            @fileRemoved="attachFileListRemoved"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>
<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileWrite from "@/components/common/file/FileWrite";
import FileView from "@/components/common/file/FileView";
import DatePicker from "@/components/common/calendar/DatePicker";
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
export default {
  name: "TransactionTradeInfoTbl",
  components: { Input, InputNumber, DatePicker, FileView, FileWrite, TableViewWithTitle },
  props: {
    itemData: Object,
    taxValidation: String,
    taxValidationName: String,
    taxInvoiceNo: String,
    taxInvoiceDate: String,
    //taxInvoiceApprovalNum: String,
    isCreate: Boolean,
    attachFileList: Array,
  },
  computed: {
    taxInvoiceNoSync: {
      get() {
        return this.autoHypen(this.taxInvoiceNo);
      },
      set(value) {
        this.$emit("update:taxInvoiceNo", value);
      },
    },
    taxInvoiceDateSync: {
      get() {
        return this.taxInvoiceDate;
      },
      set(value) {
        this.$emit("update:taxInvoiceDate", value);
      },
    },

    attachFileListSync: {
      get() {
        return this.attachFileList;
      },
      set(value) {
        this.$emit("update:attachFileList", value);
      },
    },
    computedTaxValidation() {
      const { issue } = this.itemData;
      let ret = "";
      if (issue === "FAIL") {
        ret = "is-nonvalid";
      }

      return ret;
    },
  },
  methods: {
    attachFileListUploaded(files) {
      this.attachFileListSync = this.attachFileListSync.concat(files);
    },
    attachFileListRemoved(itemToRemove) {
      this.attachFileListSync = this.attachFileListSync.filter((item) => item !== itemToRemove);
    },
    autoDash(value) {
      let trimNum = value;
      trimNum.trim();
      this.taxInvoiceNoSync = this.autoHypen(trimNum);
    },
    autoHypen(taxInvoiceNo) {
      if (!taxInvoiceNo) {
        return "";
      } else {
        taxInvoiceNo = taxInvoiceNo.replace(/[-]/g, "");
        let tempNum = "";
        if (taxInvoiceNo.length < 8) {
          return taxInvoiceNo;
        } else if (taxInvoiceNo.length < 16) {
          tempNum += taxInvoiceNo.slice(0, 8);
          tempNum += "-";
          tempNum += taxInvoiceNo.slice(8, 16);
          return tempNum;
        } else if (taxInvoiceNo.length < 24) {
          tempNum += taxInvoiceNo.slice(0, 8);
          tempNum += "-";
          tempNum += taxInvoiceNo.slice(8, 16);
          tempNum += "-";
          tempNum += taxInvoiceNo.slice(16, 24);
          return tempNum;
        } else {
          tempNum += taxInvoiceNo.slice(0, 8);
          tempNum += "-";
          tempNum += taxInvoiceNo.slice(8, 16);
          tempNum += "-";
          tempNum += taxInvoiceNo.slice(16, 24);
          return tempNum;
        }
      }
    },
    onClickTaxInvoice() {
      this.$emit("onClickTaxInvoice");
    },
  },
};
</script>
<style lang="scss" scoped>
.is-valid {
  color: blue;
}
.is-nonvalid {
  color: red;
  font-weight: 500;
}
.is-unknown {
  color: #607d8b;
}
</style>
